exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-post-tsx-content-file-path-opt-build-repo-src-posts-problem-with-porfolios-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/posts/problem-with-porfolios/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-build-repo-src-posts-problem-with-porfolios-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-opt-build-repo-src-posts-why-you-need-a-map-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/posts/why-you-need-a-map/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-build-repo-src-posts-why-you-need-a-map-index-mdx" */),
  "component---src-templates-words-tsx": () => import("./../../../src/templates/words.tsx" /* webpackChunkName: "component---src-templates-words-tsx" */)
}

