import React from 'react';
import { ThemeProvider } from './styles/ThemeContext';
import GlobalStyles from './styles/GlobalStyles';

interface AppProps {
  children: React.ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default App;
