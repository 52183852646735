export const COLORS = {
  // Backgrounds & Surfaces
  background: {
    light: 'hsla(232, 16%, 99%, 100%)',
    dark: 'hsla(0, 0%, 7%, 100%)',
  },
  //remember to add a backdrop-filter with this one
  backgroundBlurred: {
    light: 'hsla(232, 16%, 99%, 90%)',
    dark: 'hsla(0, 0%, 7%, 88%)',
  },

  surface: {
    light: 'hsla(232, 16%, 97%, 100%)',
    dark: 'hsla(0, 0%, 10%, 100%)',
  },

  overlay00: {
    light: 'hsla(232, 16%, 97%, 100%)',
    dark: 'hsla(0, 0%, 9%, 100%)',
  },

  overlay01: {
    light: 'hsla(230, 12%, 32%, 6%)',
    dark: 'hsla(0, 0%, 100%, 6%)',
  },
  overlay02: {
    light: 'hsla(230, 12%, 32%, 10%)',
    dark: 'hsla(0, 0%, 100%, 8%)',
  },
  overlay03: {
    light: 'hsla(230, 12%, 32%, 16%)',
    dark: 'hsla(0, 0%, 100%, 12%)',
  },

  // On Surfaces
  onSurfaceHigh: {
    light: 'hsla(232, 12%, 32%, 100%)',
    dark: 'hsla(0, 0%, 87%, 100%)',
  },
  onSurfaceMedium: {
    light: 'hsla(232, 12%, 48%, 100%)',
    dark: 'hsla(0, 0%, 64%, 100%)',
  },
  onSurfaceLow: {
    light: 'hsla(232, 12%, 56%, 100%)',
    dark: 'hsla(0, 0%, 52%, 100%)',
  },

  // Primary color
  primary: {
    light: 'hsl(191,53%, 47%)',
    dark: 'hsl(189, 92%, 32%)',
  },
  primaryLight: {
    light: 'hsl(185, 100%, 80%)',
    dark: 'hsl(190, 100%, 25%)',
  },
  primaryDark: {
    light: 'hsl(192, 100%, 20%)',
    dark: 'hsl(190, 100%, 25%)',
  },

  // On primary color
  onPrimaryHigh: {
    light: 'hsla(0, 0%, 100%, 100%)',
    dark: 'hsla(0, 0%, 100%, 100%)',
  },
  onPrimaryMedium: {
    light: 'hsla(0, 0%, 100%, 74%)',
    dark: 'hsla(0, 0%, 100%, 74%)',
  },
  onPrimaryLow: {
    light: 'hsla(0, 0%, 100%, 38%)',
    dark: 'hsla(0, 0%, 100%, 38%)',
  },
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';
