module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#484A5B","showSpinner":false,"easing":"ease","speed":500},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Open Sans"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-144},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jonathan Esbjug, Design Portfolio","short_name":"Jonathan Esbjug","description":"The design portfolio for Jonathan Esbjug. Product designer and consultant.","lang":"en","display":"standalone","start_url":"/","icon":"src/assets/svgs/icon-path-logo.svg","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"query","background_color":"#FCFCFD","theme_color":"#484A5B","legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0fa90a8dda6f3b7f902f879c82ecc6ee"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["src/assets/svgs/icon-path-logo.svg"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
